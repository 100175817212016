const traducoes = {
  traducoes: {
    slugs: {
      menu: 'menú',
      sobreAFeira: 'sobre una feria',
      sicc: 'sicc',
      gramado: 'gramado',
      serraPark: 'serra park',
      parceiros: 'socios',
      grupoDeImportadores: 'grupo-importador',
      estacao3: 'estacion-3',
      conectech: 'conectech',
      queroVisitar: 'Quiero visitar',
      hospedagem: 'hospedaje',
      traslado: 'transferir',
      credenciamento: 'acreditación',
      agenciaDeTurismo: 'agencia de turismo',
      expositores: 'expositores',
      expositoresCategoriaFeminino: 'calcados-femininos',
      expositoresCategoriaMasculino: 'calcados-masculinos',
      expositoresCategoriaInfantil: 'calcados-infantis',
      expositoresCategoriaBolsasAcessorios: 'bolsas-e-acessorios',
      expositoresCategoriaEsportivos: 'calcados-esportivos',
      plantaBaixa: 'Planta baja',
      eMerkator: 'e-merkator',
      eMerkatorTalks: 'e-merkator-charlas',
      eMerkatorBlog: 'e-merkator-blog',
      eMerkatorTV: 'e-merkator-tv',
      eMerkatorCast: 'e-merkator-cast',
      eMerkatorPush: 'e-merkator-push',
      imprensa: 'Prensa',
      noticias: 'noticias',
      galeriaDeImagens: 'galeria-de-imagenes',
      contato: 'contacto',
      programacoes: 'emerkatortalks',
      onnoplace: 'onno-place',
      cupomBeneficios: 'cupom-beneficios',
    },
    cabecalho: {
      painelExpositor: 'Panel de expositores',
      menu: 'Menú',
      sobreAFeira: 'Sobre la Feria',
      sicc: 'SICC',
      gramado: 'Gramado',
      serraPark: 'Serra Park',
      parceiros: 'Socios',
      grupoDeImportadores: 'Grupo de importadores',
      estacao3: 'Estación 3',
      conectech: 'Conectech',
      queroVisitar: 'Quiero visitar',
      hospedagem: 'Alojamiento',
      traslado: 'Transferir',
      credenciamento: 'Acreditación',
      agenciaDeTurismo: 'Agencia de Turismo',
      expositores: 'Expositores',
      plantaBaixa: 'Planta baja',
      eMerkator: 'eMerkator',
      eMerkatorTalks: 'Charlas de eMerkator',
      eMerkatorBlog: 'Blog de eMerkator',
      eMerkatorTV: 'eMerkator TV',
      eMerkatorCast: 'eMerkator Cast',
      eMerkatorPush: 'eMerkator Push',
      imprensa: 'Prensa',
      noticias: 'Noticias',
      galeriaDeImagens: 'Galería de Imágenes',
      contato: 'Contacto',
      onnoPlace: 'ONNO place',
    },
    paginas: {
      inicial: {
        secaoSicc: {
          titulo: 'SICC - Salón Internacional del Cuero y Calzado',
          subTitulo: 'La nueva cara de la moda',
          botaoQueroVisitar: 'Quiero visitar',
          botaoGaleria: 'Galería',
          conteudo: '',
        },
        secaoGramado: {
          titulo: 'Gramado',
          conteudo: '',
        },
        secaoSerraPark: {
          titulo: 'Serra Park',
          subTitulo: 'Viação Férrea, nº 100, Gramado / RS',
          conteudo: '',
          tituloCaracteristicas: 'Características',
          caracteristicas: '',
        },
        secaoParceiros: {
          titulo: 'Socios',
          conteudo: '',
        },
        secaoGrupoDeImportadores: {
          titulo: 'Grupo de importadores',
          conteudo: '',
        },
        secaoEstacao3: {
          titulo: 'Estación 3',
          conteudo: '',
        },
        secaoConectech: {
          titulo: 'Conectech',
          conteudo: '',
        },
        secaoQueroVisitar: {
          titulo: 'Quiero visitar',
          hospedagem: {
            titulo: 'ALOJAMIENTO',
            conteudo:
              '<p>Merkator Feiras e Eventos, promotora de la SICC, brindará hospedaje de cortesía en hoteles de la Serra Gaúcha, durante el período de la feria. <br /> Los amenities son exclusivos para minoristas del sector calista.</p><p>Las solicitudes de hospedaje para el SICC estarán abiertas a partir del 10 de marzo de 2025.</p>',
            textoBotao: 'Solicite cortesía aquí',
            textoEmBreve: 'Disponible pronto',
            subTextoBotao: '',
          },
          translado: {
            titulo: 'TRANSFERIR',
            conteudo:
              '<p> Traslado desde el Aeropuerto Internacional Salgado Filho, en Porto Alegre - Rio Grande do Sul, en & eacute; los hoteles acreditados serán acreditados; realizadas a través del transporte oficial de la SICC, con el objetivo de ofrecer mayor comodidad, confort y seguridad a nuestros visitantes. </p>',
            textoBotao: 'Tiempos y valores aquí',
            textoEmBreve: 'Disponible pronto',
            subTextoBotao: '',
          },
          credenciamento: {
            titulo: 'Acreditación',
            conteudo:
              '<p> Merkator quiere facilitar su entrada a la feria. Con este procedimiento tienes & aacute; una insignia & aacute; listo esperándote en la ventana & ecirc; de Acreditación Temprana y poder & aacute; Ingrese a la feria para conocer los principales lanzamientos primavera-verano en la industria de la cal y accesorios en Brasil. </p>',
            textoBotao: 'Registrarse aquí',
            textoEmBreve: 'Disponible pronto',
            subTextoBotao: 'Acreditación disponible próximamente',
          },
        },
        secaoAgenciaDeTurismo: {
          titulo: 'Agencia de turismo',
          conteudo:
            '<p> Sunpower Turismo, & eacute; agencia de viajes oficial de la feria. </p> <p> Cuenta con un equipo de profesionales calificados y con experiencia, brindando siempre un servicio personalizado de calidad, con un enfoque en la satisfacción del cliente. </p> <p> Ofrecemos todos los servicios de logística de viajes con descuentos especiales para los participantes / expositores de la feria. </p> <p> - Alojamiento; <br /> - Pasaje a & eacute; área; <br /> - Alquiler de coches. </p> <p> ¡Te recordamos que las reservas solicitadas con anticipación garantizan las mejores tarifas y condiciones! </p> <p> Más información & ccedil; & otilde; es, siga nuestros contactos: <br /> Sunpower Turismo <br /> +55 54-3286 5033 <br /> reservas@sunpowerturismo.com.br | www.sunpowerturismo.com.br </p>',
          subTitulo: 'Agencia Oficial de Turismo',
        },
        secaoExpositores: {
          titulo: 'Expositores',
          subTitulo: 'Seleccione el producto deseado:',
          subTituloBreve: 'En breve más información.',
          categorias: {
            feminino: 'Mujeres',
            masculino: 'Hombres',
            infantil: 'Niños',
            acessorios: 'Bolsos, accesorios y artículos de piel',
            esportivos: 'Deporte',
          },
        },
        secaoPlantaDaFeira: {
          titulo: 'Planta baja',
          description: 'Haga click aquí para descargar',
        },
        secaoEMerkator: {
          titulo: 'eMerkator',
          subTitulo: 'Te conecta con el mercado',
          textoLinkProgramacao: 'Agenda completa',
        },
        secaoImprensa: {
          titulo: 'Prensa',
          noticias: 'Noticias',
          galeriaDeImagens: 'Galería de imágenes',
          falarComImprensa: 'Quiero hablar con la oficina de prensa',
        },
      },
      noticias: {
        secaoNoticias: {
          titulo: 'Noticias',
        },
      },
      noticia: {
        secaoNoticia: {
          titulo: 'Noticias',
          tituloNoticia: '',
          legendaImagem: '',
          textoTags: 'TAGS',
          conteudo: '',
        },
      },
      galerias: {
        titulo: 'Galerías',
      },
      programacoes: {
        secaoProgramacoes: {
          titulo: 'eMerkatorTalks',
          diasSemana: {
            0: 'Domingo',
            1: 'Lunes',
            2: 'Martes',
            3: 'Miércoles',
            4: 'Jueves',
            5: 'Viernes',
            7: 'Sábado',
          },
        },
      },
      programacao: {
        secaoProgramacao: {
          titulo: 'eMerkatorTalks',
        },
      },
      traslado: {
        titulo: 'Transferir',
        description:
          '<p>La transferencia desde el Aeropuerto Internacional Salgado Filho, en Porto Alegre - Rio Grande do Sul, a los hoteles seleccionados será realizado por el equipo de transporte oficial subcontratado por SICC como manera de proporcionar a los visitantes una mayor comodidad y seguridad.</p><p>El equipo de Merkator, SICC - Salón Internacional del Cuero y Calzado, estará en la terminal de llegadas coordinando las transferencias de hotel mencionadas anteriormente.</p><p><b>Contacto:</b> <br />+55 54 99144-4261 (Whatsapp e teléfono) <br /> +55 54 99163-1410 ( Whatsapp e teléfono - Atendimento Geral) <br />+55 54 3282 5445 <br />eventos3@brockerturismo.com.br</p>',
        textAlert:
          '<b>* Los horarios anteriores pueden cambiar según la necesidad y la demanda.</b>',
        textAlert2:
          'Es <b>OBLIGATORIO</b> que el cliente se presente en el stand oficial del equipo de transferencia, ubicado en el registro 2 de la feria, para confirmar y/o programar el horario de transferencia de Gramado/Canela a Porto Alegre. Para mayor información sobre transferencias y compra anticipada comunicarse con Catherine al +55 (51) 99651-5213',
        textTrasnporteOficial: 'Transporte Oficial',
        textBotaoComprar: 'Entradas Aquí',
        // textBotaoComprar: 'Ventas abiertas pronto',
        textBotaoValores: 'Valores',
        textEventoDiaUm: 'Domingo 30/06',
        textEventoDiaDois: 'Lunes 01/07',
        textEventoDiaTres: 'Marte 02/07',
        textEventoDiaQuatro: 'Miércoles 03/07',
        textEventoDiaCinco: 'Jueves 04/07',
        textTitleDescriptionDiaUm:
          'Salida desde el Aeropuerto de Porto Alegre hacia los hoteles socios de la Feria.',
        textDescriptionDiaUm: '<b>10h | 12h | 14h | 16h | 18h | 20h *</b>',
        textTitleDescriptionDiaDois:
          'Salida desde el Aeropuerto de Porto Alegre hacia la feria.',
        textDescriptionDiaDois: '<b>8h | 10h | 12h | 14h *</b>',
        textTitleDescriptionDiaTres:
          'Salida desde el Aeropuerto de Porto Alegre hacia la feria.',
        textTitleDescriptionDiaTresV:
          'Gramado al Aeropuerto Salgado Filho (Embarque en la feria)',
        textDescriptionDiaTres: '<b>8h | 10h | 12h | 14h*</b>',
        textDescriptionDiaTresV: '<b>14h</b>',
        textTitleDescriptionDiaQuatro:
          'Gramado al Aeropuerto Salgado Filho (Embarque en la feria)',
        textTitleDescriptionDiaQuatroV:
          'Gramado/Canela para Aeroporto Salgado Filho',
        textDescriptionDiaQuatro: '<b>10h | 12h | 14h | 16h *</b>',
        textDescriptionDiaQuatroV:
          '(Embarque na feira)<br /><br /><b>01h | 10h | 11h30min | 13h | 14h | 15h30min</b><br /><br />Todos os horários de retorno devem ser agendados previamente nos balcões de traslado oficial, na feira.',
        textTitleDescriptionDiaCinco:
          'Gramado al Aeropuerto Salgado Filho (Recogida la feria)',
        textDescriptionDiaCinco: '<b>10h | 12h | 14h | 16h *</b>',
        titleTicketUnico: 'BILLETE <br /> SENCILLO',
        titleDescriptionUnico:
          'Aeropuerto (POA) > Gramado/Canela o Gramado/Canela > Aeropuerto (POA)',
        precoTicketUnico: 'R$100,00',
        comboTicketUnico: 'COMBO <BR /> IDA Y VUELTA',
        comboDescriptionUnico:
          'Aeropuerto (POA) > Gramado/Canela y Gramado/Canela > Aeropuerto (POA)',
        precoComboTicketUnico: 'R$260,00',
        titleTicketAvulso: 'TRASLADO <br /> INTERNO',
        descriptionTicketAvulso:
          'Hotel acreditado / Serra Park – Serra Park / hotel acreditado. Billete Sencillo (todos los días)',
        precoTicketAvulso: 'R$100,00',
        titleComboFeira: 'COMBO<br />FEIRA',
        descriptionComboFeira:
          'Hotel acreditado / Serra Park – Serra Park / hotel acreditado. Billete Sencillo (un día)',
        precoComboFeira: 'R$100,00',
        porPessoa: 'por persona',
        exceptionPrice: '',
        textCompreAqui: 'ENTRADAS AQUÍ',
        // textCompreAqui: 'Ventas abiertas pronto',
        descriptionValor:
          '<p> <b> Comprando la entrada sencilla o el combo, obtienes el traslado interno gratis durante los días de feria.</b></p>',
      },
      expositores: {
        titulo: 'Expositores',
        botaoCategoria: {
          listaCompleta: 'Lista llena',
          feminino: 'Calzado para Mujer',
          masculino: 'Calzado para Hombres',
          infantil: 'Calzado para Niños',
          acessorios: 'Bolsa Y Accessorios',
          esportivos: 'Calzado Desportivo',
        },
      },
      eMerkatorTv: {
        titulo: 'eMerkator TV',
      },
      pagina404: {
        titulo: 'Página no encontrada.',
      },
    },
    componentes: {
      painelExpositor: {
        email: 'Correo electrónico',
        senha: 'Contraseña',
        entrar: 'Enter',
        cancelar: 'Cancelar',
        esqueciMinhaSenha: 'Olvidé mi contraseña',
      },
      secao: {
        botaoVoltar: 'Regresar',
      },
      cabecalho: {
        linguagem: 'Idioma',
        portugues: 'portugués',
        ingles: 'inglés',
        espanhol: 'Español',
      },
      rodape: {
        baixeNossoApp: 'Descarga nuestra aplicación',
        disponivelNa: 'Disponible en',
      },
      secaoEMerkator: {
        programacaoCompleta: 'Conferencias',
      },
    },
  },
};

export default traducoes;
