import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  BotaoLink,
  Col,
  Conteudo,
  Row,
  Secao,
  SecaoBotaoVoltar,
  SecaoCabecalho,
  SecaoSubTitulo,
  SecaoTitulo,
  Tradutor,
  Video,
} from './styles';
import Head from '@alweb-merkator/shared/components/Head';

const SecaoSicc = ({ idPage, api, ...props }) => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const secaoId = t('slugs.sicc');

  const [sicc, setSicc] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    _handleGetSicc();
  }, []);

  const _handleGetSicc = async () => {
    await api.get(`/blnews/${idPage}`).then((response) => {
      const dados = response.data[0];
      const dado = {
        title: '',
        content: '',
        description: '',
        image: dados.image,
        video: dados.video,
        image_description: dados.image_description,
        image_mask:
          'https://paineldoexpositor.com.br/uploads/configuration/config_678656566f2f14_09880506.jpg',
      };

      if (lang !== 'pt') {
        dado.title = dados['title_' + lang];
        dado.content = dados['content_' + lang];
        dado.description = dados['description_' + lang];
        dado.image_mask =
          lang === 'es'
            ? 'http://paineldoexpositor.com.br/uploads/media/media_6666eedd80f821_36997099.png'
            : 'http://paineldoexpositor.com.br/uploads/media/media_6666eede3bbce3_51560619.png';
      } else {
        dado.title = dados.title;
        dado.content = dados.content;
        dado.description = dados.description;
      }
      setSicc(dado);
      setIsLoading(true);
    });
  };

  const changeHead = () => (
    <Head title={sicc.title} description={sicc.description} />
  );

  return (
    <Secao {...props} id={secaoId}>
      {isLoading ? changeHead() : null}
      <SecaoCabecalho>
        <SecaoTitulo>
          <Tradutor path={isLoading ? sicc.title : ''} />
        </SecaoTitulo>
        <SecaoBotaoVoltar />
      </SecaoCabecalho>
      <SecaoSubTitulo className="mb-4">
        <Tradutor path={isLoading ? sicc.description : ''} />
      </SecaoSubTitulo>
      <Row>
        <Col md="6">
          <Conteudo
            dangerouslySetInnerHTML={{
              __html: isLoading ? sicc.content : '',
            }}
          />
        </Col>
        {isLoading && sicc.video && (
          <Col md="6">
            <Video
              imagemPlaceholder={sicc.image_mask}
              videoEmbedded={sicc.video}
              showIcon={true}
            />
            <Row className="m-0">
              <Col className="pl-0">
                <BotaoLink
                  className="btn btn-primary btn-block"
                  to={`/${lang}#${t('slugs.queroVisitar')}`}
                >
                  <Tradutor path="paginas.inicial.secaoSicc.botaoQueroVisitar" />
                </BotaoLink>
              </Col>
              <Col className="pr-0">
                <BotaoLink
                  className="btn btn-primary btn-block"
                  to={`/${lang}/${t('slugs.galeriaDeImagens')}`}
                >
                  <Tradutor path="paginas.inicial.secaoSicc.botaoGaleria" />
                </BotaoLink>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Secao>
  );
};
SecaoSicc.propTypes = {
  idPage: PropTypes.any,
  api: PropTypes.any,
};
export default SecaoSicc;
